<template>
  <div class="vuse-content-wrapper">
    <smart-breadcrumbs :items="navigationElements" />
    <v-card>
      <div class="filters">
        <h2 class="text-center">
          Examen {{ course.name ? ' - ' : '' }} <small>{{ course.name }}</small>
          <help class="mx-2" :helpId="44" :xSmall="true" />
        </h2>
        <v-form>
          <!-- <h4 class="mb-4">Datos del examen</h4> -->
          <v-row class="mx-0 mt-4">
            <v-col cols="12" md="3" sm="5">
              <v-text-field
                :rules="[rules.required]"
                v-model="exam.attempts"
                label="Intentos"
                step="1"
                min="1"
                type="number"
              />
            </v-col>
            <v-col cols="12" md="3" sm="5">
              <v-text-field
                :rules="[rules.required]"
                v-model="exam.duration"
                label="Duración"
                suffix="min"
                step="1"
                min="1"
                type="number"
              />
            </v-col>
            <v-col cols="12" md="3" sm="5">
              <v-text-field
                :rules="[rules.required]"
                v-model="exam.points"
                label="Puntos"
                step="1"
                min="1"
                type="number"
              />
            </v-col>
            <v-col cols="12" md="3" sm="5" class="pr-0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                    class="mb-2"
                    style="float: right"
                    @click="updateExam()"
                  >
                    Actualizar
                  </v-btn>
                </template>
                <span>Actualizar datos del examen</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <h4 class="mx-auto">Listado de preguntas</h4>
        </v-row>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              class="mb-2"
              style="float: right"
              @click.prevent="handleCreate()"
              >Crear
            </v-btn>
          </template>
          <span>Crear Pregunta</span>
        </v-tooltip>
        <label>Filtrar por:</label>
        <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row mx-0">
          <v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              />
            </v-col>
          </v-row>
          <v-btn
            color="primary"
            style="min-width: auto; position: absolute; right: 50px; height: 25px"
            dark
            class="px-1 py-1"
            v-if="filterActivated"
            @click.prevent="
              filterActivated = false
              cleanFilters()
              getQuestions()
            "
          >
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>

          <v-tooltip top>
             <template v-slot:activator="{ on, attrs }">
              <v-btn small v-bind="attrs"
                v-on="on"
                color="primary"
                style="min-width: auto; position: absolute; right: 10px; height: 25px"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getQuestions()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :itemKey="'id'"
        :hideFooter="true"
        :hideHeader="false"
        :multiSort="false"
        :loading="loading"
        :class="'my-2 pb-2'"
        :sortable="false"
        :page="page"
        :pages="pages"
        :extraButtoms="extraButtoms"
        :showCreate="false"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
        @showAnswers="handleAnswersRedirect($event)"
      />
    </v-card>
    <modal name="crudModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <v-card class="pa-4" elevation="0">
        <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Pregunta</h2>
        <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Pregunta</h2>

        <span v-if="flow === 'edit' && item.created_at"
          >Fecha de creación:
          {{
            createdAt.toLocaleDateString('es-VE', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          }}. {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
        >
        <v-form ref="formCompany" v-model="valid" style="width: 100%">
          <v-row class="mx-0"
            ><v-col cols="12">
              <v-text-field
                counter
                :rules="[rules.required]"
                label="Escribe la pregunta"
                v-model="text"
              /> </v-col
          ></v-row>
        </v-form>
      </v-card>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'create'"
            :disabled="!valid"
            color="primary"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            v-if="flow === 'edit'"
            :disabled="!valid"
            color="primary"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn small
              style="margin: auto; margin-top: 10px" color="primary" @click="reset">
            Limpiar
          </v-btn>
          <v-btn small
            style="margin: auto; margin-top: 10px"
            color="primary"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SimpleList from '@/components/Listing/SimpleList.vue'
  import { decryptData } from '@/utils/encryption'

  export default {
    name: 'ExamQuestions',
    components: {
      SimpleList,
    },
    data() {
      return {
        config: {},
        metrics: [],
        loading: true,
        course: {},
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        headers: [
          {
            text: '#',
            value: 'id',
            align: ' d-none',
          },
          { text: 'Pregunta', value: 'text' },
          { text: '# Respuestas', value: 'answers', align: 'right' },
          { text: 'Acciones', value: 'actions', align: 'center', width: '150px' },
        ],
        items: [],
        navigationElements: [
          {
            text: 'Elearning',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/AdminPanel',
            href: '/business/elearning/admin-panel',
          },
          {
            text: 'Cursos',
            disabled: false,
            query: null,
            params: null,
            name: 'business/elearning/Courses',
            href: '/business/elearning/courses',
          },
          {
            text: 'Examen',
            disabled: true,
          },
        ],
        metricsSearched: [{ id: 0, name: 'No posee empresa principal' }],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
        },
        extraButtoms: [
          {
            text: 'Respuestas',
            icon: 'mdi-list-status',
            event: { active: true, name: 'showAnswers' },
          },
        ],
        exam: {
          attempts: 3,
          duration: 30,
          points: 10,
        },
        text: '',
        loadingText: 'Cargando registros',
        valid: true,
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 200 : true) || 'Debe poseer menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
      }
    },
    watch: {},
    created() {
      this.firstOrCreateExam()
      this.getCourse()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
    },
    methods: {
      cleanFilters() {
        this.filters = {
          search: '',
        }
      },

      async getQuestions() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let periodicityString = ''
          if (this.filters.search !== '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.periodicity && this.filters.periodicity.id) {
            periodicityString = '&periodicity=' + this.filters.periodicity.id
          }
          queryParams = searchString + periodicityString
        }
        this.$axios
          .get(
            'questions?exam_id=' +
              this.exam.id +
              '&page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }

            this.loading = false
          })
      },

      async getCourse() {
        await this.$axios
          .get('courses/' + this.$route.params.courseId + '?with[]=cover')
          .then((response) => {
            this.course = response.data
          })
      },

      async firstOrCreateExam() {
        await this.$axios
          .post('exams', {
            course_id: this.$route.params.courseId,
            attempts: this.exam.attempts,
            points: this.exam.points,
            duration: this.exam.duration,
          })
          .then((response) => {
            this.exam = response.data.exam
            this.getQuestions()
          })
      },

      async updateExam() {
        await this.$axios
          .patch('exams/' + this.exam.id, {
            attempts: this.exam.attempts,
            points: this.exam.points,
            duration: this.exam.duration,
          })
          .then((response) => {
            this.exam = response.data.exam
            this.$swal({
              title: '¡Listo!',
              text: 'Examen actualizado',
              icon: 'success',
              timer: 2000,
            })
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.text = ''

        this.$modal.show('crudModal')
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getQuestions()
      },

      handleAnswersRedirect(question) {
        this.$router.push({
          name: 'business/elearning/QuestionAnswers',
          params: { questionId: question.id },
        })
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.text = this.item.text
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.formCompany.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formCompany.resetValidation()
      },

      async createItem() {
        this.$axios
          .post('questions', {
            exam_id: this.exam.id,
            text: this.text,
          })
          .then(() => {
            this.page = 1
            this.getQuestions()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        this.$axios
          .patch('questions/' + this.item.id, {
            exam_id: this.exam.id,
            text: this.text,
          })
          .then(() => {
            this.page = 1
            this.getQuestions()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        this.$axios.delete('questions/' + item.id).then(() => {
          this.page = 1
          this.items = []
          this.getQuestions()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getQuestions()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
</style>
